import { useMemo } from 'react'

import { useQuery } from '@tanstack/react-query'
import { t } from 'i18next'

import HeaderPages from '~/components/HeaderPages'
import PageTitle from '~/components/PageTitle'
import { getRetailMediaAccountType, isPublisher } from '~/helpers'
import useQueryParams from '~/hooks/useQuery'
import AccountInfo from '~/modules/retailMedia/components/AccountInfo'
import CampaignForm from '~/modules/retailMedia/components/CampaignForm'
import { getAdvertiserService } from '~/modules/retailMedia/services/advertiser'
import { getPublisherService } from '~/modules/retailMedia/services/publisher'

const CampaignCreate = () => {
  const searchParams = useQueryParams()
  const id = searchParams.get('rmid') as PublisherId | AdvertiserId

  const { data } = useQuery({
    queryKey: ['getAccountInfo', id],
    enabled: !!id,
    queryFn: async () => {
      const service = isPublisher() ? getAdvertiserService : getPublisherService

      const res = await service({ url: { id }, params: { account_info: true } })

      return res.data
    }
  })

  const headerProps: Partial<HeaderPageProps> = useMemo(() => {
    if (!id) {
      return {
        backTo: '/retail-media/campaigns',
        labelBreadcrumb: t('rm:backToCampaigns')
      }
    }

    return {
      backTo: `/retail-media/campaigns?rmid=${id}`,
      labelBreadcrumb: `${t('rm:backToCampaignsWithName', { name: data?.name })}`
    }
  }, [data?.name, id])

  return (
    <>
      <PageTitle title={`${t('rm:Campaign')} > ${t('rm:create')}`} />

      <HeaderPages
        {...headerProps}
        {...(id && {
          title: (
            <AccountInfo
              id={id}
              retailMediaAccountType={getRetailMediaAccountType()}
            />
          )
        })}
      />

      <HeaderPages title={t('rm:createCampaign')} />

      <CampaignForm />
    </>
  )
}

export default CampaignCreate
