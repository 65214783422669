import { useLayoutEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { t } from 'i18next'

import AlertDismissibleNew from '~/components/AlertDismissibleNew'
import { DatepickerHeader } from '~/components/datepicker-header'
import HeaderPages from '~/components/HeaderPages'
import { LogoStore } from '~/components/LogoStore'
import PageTitle from '~/components/PageTitle'
import Status from '~/components/Status'
import { Badge } from '~/components/UI/Badge'
import {
  formatDateToString,
  history,
  isAdvertiser,
  isPublisher,
  isReadOnly,
  requestRetailMediaAccount
} from '~/helpers'
import { handleErrorMessage } from '~/helpers/errors'
import { useAppInfo } from '~/hooks/useAppInfo'
import useQueryParams from '~/hooks/useQuery'
import CampaignLogs from '~/modules/retailMedia/components/CampaignLogs'
import { statusType } from '~/modules/retailMedia/components/StatusBar/statusType'
import ToogleActiveCampaign from '~/modules/retailMedia/components/ToogleActiveCampaign'
import { getLabelAdType } from '~/modules/retailMedia/dtos/common/campaign/format'
import { getCampaign } from '~/modules/retailMedia/store/campaign/actions'
import { useAppSelector } from '~/store/hooks'

import DebugSettings from './components/Debug/Settings'
import DetailsViewCampaign from './components/Details'

const CampaignView = () => {
  const { id } = useParams()

  const { isWhiteLabel } = useAppInfo()

  const query = useQueryParams()
  const callback_url = query.get('callback_url')

  const dispatch = useDispatch()
  const {
    campaign: {
      item: { data, loading, error }
    }
  } = useAppSelector(state => state.retailMedia)

  // check is persited data on redux is valid
  const isValidContent = useMemo(() => id === data?.id, [data?.id, id])

  const { startDate, endDate } = useAppSelector(state => state.datepickerHeader)

  const queryParams = useMemo(
    () => ({
      start_date: formatDateToString(startDate, 'YYYY-MM-DD'),
      end_date: formatDateToString(endDate, 'YYYY-MM-DD'),
      campaign_id: id
    }),
    [startDate, endDate, id]
  )

  useLayoutEffect(() => {
    dispatch(getCampaign({ ...queryParams, account_info: true }))
  }, [dispatch, queryParams])

  /**
   * Handle header pages props
   */

  const headerPageProps: HeaderPageProps = useMemo(() => {
    if (error) {
      return {
        title: `${t('rm:Campaign')} - ${t('rm:campaign.error.loading')}`,
        goBack: () => history.back(),
        labelBreadcrumb: t('rm:back')
      }
    }

    if (!isValidContent) {
      return {
        title: `${t('rm:Campaign')} - ${t('rm:Loading')}`,
        goBack: () => history.back(),
        labelBreadcrumb: t('rm:back')
      }
    }

    const { publisher, advertiser, title = null, id, active } = data || {}
    const accountData = isPublisher() ? advertiser : publisher
    const { name: accountName, id: accountId } = accountData || {}
    const backgroundLogo =
      data?.[requestRetailMediaAccount]?.accountTheme?.header_color
    const linkLogo = data?.[requestRetailMediaAccount]?.logo
    const alt = data?.[requestRetailMediaAccount]?.name

    const labelBreadcrumb =
      (!isWhiteLabel || isPublisher()) && accountId && accountName
        ? `${accountName ? t('rm:backToCampaignsWithName', { name: accountName }) : t('rm:backToCampaigns')}`
        : `${t('rm:backToCampaigns')}`

    const backToApp =
      callback_url || `/retail-media/campaigns?rmid=${accountId}`

    const backTo =
      isWhiteLabel && !isPublisher() ? '/retail-media/campaigns' : backToApp

    const statusData: StatusData = {
      label: statusType[data?.status]?.label || data?.status,
      type: statusType[data?.status]?.color || 'default'
    }

    const goBack = accountId ? null : () => history.back()

    return {
      titleSideRightComponent: (!isWhiteLabel || isPublisher()) && (
        <LogoStore
          size="medium"
          backgroundLogo={backgroundLogo}
          linkLogo={linkLogo}
          alt={alt}
        />
      ),
      title: loading ? t('rm:Loading') : title,
      subtitle: accountName ? (
        <div className="d-inline-flex">
          <Status status={statusData} />
          {(isPublisher() || isReadOnly()) && (
            <div className="ps-2 ms-2 border-start border-tertiary">
              <Badge size="large" template={active ? 'success' : 'warning'}>
                {active ? t('rm:activeCampaign') : t('rm:disabledCampaign')}
              </Badge>
            </div>
          )}
          <div className="ps-2 ms-2 border-start border-tertiary">
            {t('rm:campaignType')}: {getLabelAdType(data?.adType)}
          </div>
        </div>
      ) : null,
      backTo,
      goBack,
      labelBreadcrumb,
      className: 'has-datepicker',
      sideRightComponent: (
        <>
          {!!id && isAdvertiser() && !isReadOnly() && (
            <ToogleActiveCampaign showLabel data={{ id, active }} />
          )}
          <DatepickerHeader />
        </>
      )
    }
  }, [isValidContent, data, isWhiteLabel, callback_url, loading, error])

  /**
   * Handle head title tag
   */
  const pageTitle = useMemo(() => {
    if (error) {
      return `${t('rm:Campaign')} - ${t('rm:campaign.error.loading')}`
    }

    if ((!isValidContent && !loading) || loading)
      return `${t('rm:Campaign')} - ${t('rm:Loading')}`

    if (isValidContent && !!data?.title)
      return `${t('rm:Campaign')} - ${data.title}`

    return t('rm:Campaign')
  }, [data, isValidContent, loading, error])

  return (
    <>
      <PageTitle title={pageTitle} />

      <HeaderPages {...headerPageProps} />

      {!!error && (
        <AlertDismissibleNew
          title={t('rm:somethingWentWrong.alert')}
          text={handleErrorMessage({
            error,
            fallbackMessage: t('rm:pages.campaign.view.fallback.message')
          })}
        />
      )}

      {!error && (
        <>
          <DebugSettings />

          {isValidContent && (
            <>
              <DetailsViewCampaign data={data} loading={loading} />

              <CampaignLogs campaignId={id} />
            </>
          )}
        </>
      )}
    </>
  )
}

export default CampaignView
